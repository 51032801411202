<template>
  <div>
    <the-header></the-header>
    <main class="main">
      <the-report></the-report>
    </main>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "../components/layout/the-header.vue";
import TheFooter from "../components/layout/the-footer.vue";
import TheReport from "../components/the-report.vue";

export default {
  name: "Home",

  components: {
    TheHeader,
    TheFooter,
    TheReport
  }
};
</script>
