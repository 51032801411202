<template>
  <div class="main__pages">
    <div v-if="loader" class="main__loader">
      <app-loader></app-loader>
    </div>
    <div v-else>
      <div v-if="emptyData" class="pages__empty">
        <empty-page></empty-page>
      </div>
      <div v-else>
        <div class="block__balance">
          <section v-if="created">
            <div>
              Остаток по выплатам
              <span v-if="nopaydOrdersSum"
                >за {{ nopaydOrdersSum }} заказов</span
              >
            </div>
            <h3>
              {{ getBalanceAll | rounding }}
              <span style="font-weight: normal; font-size: 16px">₽</span>
            </h3>
          </section>
          <section v-else>
            <Lottie :options="defaultOptions" :width="130" :height="130" />
          </section>
        </div>
        <section class="main__pages--block">
          <div id="changeYear">
            <img
              class="chevron-left"
              :class="disabledYearLeft ? `chevron-disabled disabled` : ``"
              :disabled="disabledYearLeft"
              src="../assets/images/chevron-left.svg"
              alt="Стрелка"
              title="Год назад"
              @click="changeYear(-1, disabledYearLeft)"
            />
            {{ selectedYear }}
            <img
              class="chevron-right"
              :class="disabledYearRight ? `chevron-disabled disabled` : ``"
              :disabled="disabledYearRight"
              src="../assets/images/chevron-right.svg"
              alt="Стрелка"
              title="Год вперед"
              @click="changeYear(1, disabledYearRight)"
            />
          </div>
          <div class="main__pages--filters">
            <div
              v-for="mounth in getFormatedMonth"
              :key="mounth.id"
              class="main__pages--mounth"
              :class="{ active: mounth.selected, disabled: mounth.disabled }"
              :disabled="mounth.disabled"
              @click="changeSelectedMounth(mounth.id, mounth.disabled)"
            >
              {{ mounth.name }}
              <div v-if="mounth.pill" :class="`pill__${mounth.pill}`"></div>
            </div>
          </div>
        </section>
        <section
          v-if="selectedMounth && selectedYear"
          class="main__pages--section"
        >
          <report-title
            :mounth="selectedMounthName"
            :year="selectedYear"
            :short="shortTable"
            @downloadExcel="downloadExcel"
            @changeShortTable="changeShortTable"
          ></report-title>
          <report-orders-table
            :shortTable="shortTable"
            :orderStatusList="orderStatusList"
            :selectedOrders="selectedOrders"
            :getOrdersSum="getOrdersSum"
            :getDriverSum="getDriverSum"
          ></report-orders-table>
        </section>
        <section
          v-if="selectedMounth && selectedYear && getDriverSum"
          class="main__pages--section"
        >
          <report-payment-table
            :month="selectedMounthName"
            :year="selectedYear"
            :vedomostyUniq="vedomostyUniq"
            :selectedVedomost="selectedVedomost"
            :getVedomostySumPercent="getVedomostySumPercent"
            :endDateVedomost="endDateVedomost"
            :getAllMounthPayedSum="getAllMounthPayedSum"
            :getMounthPayedSum="getMounthPayedSum"
            @showIncludedOrders="showIncludedOrders"
          ></report-payment-table>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// Data
import { arrayMounth } from "../data/mounths";
import { paymentList } from "../data/payment";
// Preloader
import AppLoader from "./app-loader.vue";
// EmptyData
import EmptyPage from "./empty-page.vue";
// Loader Animation
import Lottie from "vue-lottie";
import animationData from "../assets/animations/loading.js";
// Report Components
import ReportTitle from "./report/report-title.vue";
import ReportOrdersTable from "./report/report-orders-table.vue";
import ReportPaymentTable from "./report/report-payment-table.vue";
// Store
import { mapActions, mapState } from "vuex";

export default {
  name: "TheReport",
  components: {
    AppLoader,
    EmptyPage,
    Lottie,
    ReportTitle,
    ReportPaymentTable,
    ReportOrdersTable
  },
  data() {
    return {
      emptyData: false,
      paymentList: paymentList,
      arrayMounth: arrayMounth,
      selectedMounth: null,
      selectedMounthName: "",
      selectedYear: null,
      firstYear: 2021,
      ordersMonth: [],
      vedomostyData: [],
      yearToMonthData: [],
      yearToMonthDataAllYears: [],
      loader: true,
      shortTable: false,
      selectedOrders: [],
      selectedVedomost: 0,
      disabledYearLeft: false,
      created: false,
      defaultOptions: { animationData: animationData, loop: false },
      animationSpeed: 1,
      fileName: "report",
      paymentsElemets: [],
      claimData: []
    };
  },
  created() {
    this.arrayMounth.map((item) => {
      item.selected = false;
      item.disabled = true;
      item.pill = null;
    });
    this.getYearDataToEveryMonthToAllYears().then(() => {
      if (this.getLastMonthYear) {
        this.selectedYear = +this.getLastMonthYear.year;
        this.changeSelectedMounth(+this.getLastMonthYear.id + 1);
        if (this.selectedYear === this.firstYear) {
          this.disabledYearLeft = true;
        }
      }
      this.created = true;
    });
  },
  destroyed() {
    document.removeEventListener("click", this.dropHandler);
  },
  filters: {
    rounding(value, notTail = 0) {
      if (!value || value === "" || value == "0") {
        return 0;
      } else {
        let tail = "";
        value = value.toString();
        if (value.includes(".")) {
          const arr = value.split(".");
          value = arr[0];
          if (arr[1].length === 1) {
            tail = "." + arr[1] + "0";
          } else if (arr[1].length === 2) {
            tail = "." + arr[1];
          } else {
            tail =
              "." +
              (Math.round(+arr[1].slice(0, 3) / 5) * 5).toString().slice(0, 2);
          }
        } else {
          tail = ".00";
        }
        value = value.replace(/\s/g, "");
        if (notTail) {
          tail = "";
        }
        if (value.length > 3) {
          let right = value.slice(-3),
            left = value.slice(0, -3),
            leftOnce = "";
          if (left.length > 3) {
            leftOnce = left.slice(0, -3);
            left = left.slice(-3);
          }
          if (leftOnce) {
            return leftOnce + " " + left + " " + right + tail;
          } else {
            return left + " " + right + tail;
          }
        } else {
          return value + tail;
        }
      }
    }
  },
  computed: {
    ...mapState(["token", "refreshToken", "user"]),
    disabledYearRight() {
      return new Date().getFullYear() > this.selectedYear ? false : true;
    },
    startMounth() {
      let startMounth = this.selectedMounth;
      if (startMounth < 10) {
        startMounth = "0" + startMounth;
      }
      return startMounth;
    },

    startDate() {
      return `${this.selectedYear}-${this.startMounth}-01`;
    },

    endDate() {
      const monthDays = new Date(
        this.selectedYear,
        this.startMounth,
        0
      ).getDate();
      return `${this.selectedYear}-${this.startMounth}-${monthDays}`;
    },

    getDateNow() {
      return new Date(Date.now()).getTime();
    },

    endDateVedomost() {
      const monthDays = new Date(
        this.selectedYear,
        this.startMounth,
        0
      ).getDate();
      return `${monthDays}.${this.startMounth}.${this.selectedYear}`;
    },

    getOrdersSum() {
      const sum = this.ordersMonth.map((item) => +item.sumDriver);
      if (sum) {
        return +sum.reduce((a, b) => a + b, 0).toFixed(2);
      } else {
        return 0;
      }
    },

    getDriverSum() {
      const sum = this.ordersMonth.map((item) => +item.paymentSum);
      if (sum) {
        return +sum.reduce((a, b) => a + b, 0).toFixed(2);
      } else {
        return 0;
      }
    },

    orderSumNoZero() {
      return this.ordersMonth.filter((item) => +item.sumDriver > 0);
    },

    orderStatusList() {
      const orderStatusListSort = [];
      this.orderSumNoZero.map((item) => {
        // Created Date
        item.createDate
          ? (item.formatedCreateDate = new Date(
              item.createDate
            ).toLocaleDateString())
          : (item.formatedCreateDate = "");
        // Route Popover
        item.routeTitle = "";
        if (item.route && Array.isArray(item.route)) {
          item.routeTitle = item.route.join("\n");
        }
        // Invoice Number
        item.score = null;
        if (item.invoiceNumber) {
          const invoiceSum = item.invoiceNumber.length;
          item.score = item.invoiceNumber.slice(-4, invoiceSum);
        }
        // Invoice Date
        item.scoreDate = "";
        item.dateDriverPay = "";
        item.dateClientPay = "";
        item.statusDriverPay = 2;
        if (item.invoiceDate && item.invoiceDate !== "01.01.0001 0:00:00") {
          item.scoreDate = item.invoiceDate.substr(0, 10);
          // Date Client Pay
          if (item.deferredPayment && item.scoreDate) {
            const newDate = item.scoreDate.split("."),
              newDateTrue = newDate[2] + "-" + newDate[1] + "-" + newDate[0];
            item.dateClientPay = new Date(
              new Date(newDateTrue).getTime() + item.deferredPayment * 86400000
            ).toLocaleDateString();
          }
        }
        // Date Driver Pay
        if (item.paymentDate) {
          item.dateDriverPay = item.paymentDate.substr(0, 10);
          item.statusDriverPay = 1;
        }
        // OrderFileStatus
        item.orderDocClient = {
          color: "main__status--red",
          name: "нет"
        };
        item.orderDocGlp = {
          color: "main__status--red",
          name: "нет"
        };
        if (item.isCheckDocLoad) {
          item.orderDocClient = {
            color: "main__status--green",
            name: "сданы"
          };
          item.orderDocGlp = {
            color: "main__status--green",
            name: "сданы"
          };
        } else {
          if (item.isCheckDocClient) {
            item.orderDocClient = {
              color: "main__status--green",
              name: "сданы"
            };
          }
          if (item.isCheckDocGlp) {
            item.orderDocGlp = {
              color: "main__status--green",
              name: "сданы"
            };
          }
        }
        // PaymentStatus
        item.scorePayment = 2;
        if (item.paymentClientStatus) {
          item.scorePayment = 1;
          item.paymentClientStatusData = {
            class: "main__status--green",
            name: "оплачено"
          };
        } else {
          item.paymentClientStatusData = {
            class: "main__status--red",
            name: "не оплачено"
          };
        }
        if (
          item.orderDocGlp.name === "нет" ||
          item.orderDocClient.name === "нет"
        ) {
          item.scorePayment = 3;
          item.statusDriverPay = 3;
        }
        const y = +item.scorePayment - 1;
        item.paymentStatus = {
          color: `main__status--${this.paymentList[y].color}`,
          status: this.paymentList[y].status
        };
        const z = +item.statusDriverPay - 1;
        item.paymentDriverStatus = {
          color: `main__status--${this.paymentList[z].color}`,
          statusDriver: this.paymentList[z].statusDriver
        };
        // DispatherName
        item.dispatcherName =
          item.dispatcherFirstName +
          " " +
          item.dispatcherLastName.slice(0, 1).toUpperCase() +
          ".";
        // Новое
        orderStatusListSort.push(item);
      });
      return orderStatusListSort.sort((a, b) => {
        return (
          new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
        );
      });
    },
    // Сумма оплаченной претензий если есть
    getSumClaim() {
      const sum = this.claimArray.map((item) => +item.sumOrder);
      if (sum) {
        return +sum.reduce((a, b) => a + b, 0).toFixed(2);
      } else {
        return 0;
      }
    },

    // Сумма 5% по претензиям
    getClaimSum5Proc() {
      const sum = this.claimArray.map((item) => +item.sum5Proc);
      if (sum) {
        return +sum.reduce((a, b) => a + b, 0).toFixed(2);
      } else {
        return 0;
      }
    },
    // Сумма 5% по ведомостям
    getVedomostySumPercent() {
      let result = 0;
      if (this.mounthArrayUniq.length > 0) {
        const month = this.mounthArrayUniq.filter(
          (val) => val.id + 1 == this.selectedMounth
        );
        if (month.length > 0) {
          const monthItem = month[0];
          if (monthItem.sum5proc > 0) {
            result = monthItem.sum5proc;
          }
        }
      }
      return result;
    },

    getAllMounthPayedSum() {
      return (+this.getDriverSum + +this.getVedomostySumPercent).toFixed(2);
    },

    getMounthPayedSum() {
      return (+this.getOrdersSum - +this.getAllMounthPayedSum).toFixed(2);
    },

    // Уникальный массив ведомости, формирование правильного массива для вывода
    vedomostyUniq() {
      const resultVedomost = [],
        newVedomost = [...new Set(this.vedomostyData.map((obj) => obj.vedId))];
      if (newVedomost) {
        newVedomost.map((item) => {
          const sum = this.vedomostyData
              .map((val) => {
                if (val.vedId === item) {
                  return val.sum;
                } else {
                  return "";
                }
              })
              .filter((n) => n),
            sumOrder = sum.reduce((a, b) => a + b, 0).toFixed(2),
            orders = this.vedomostyData
              .map((val) => {
                if (val.vedId === item) {
                  return val.orderId;
                } else {
                  return "";
                }
              })
              .filter((n) => n),
            vedDataPlat = this.vedomostyData.find(
              (val) => val.vedId === item
            ).vedDataPlat,
            vedNumber = this.vedomostyData.find(
              (val) => val.vedId === item
            ).vedNumber,
            poluchatel = this.vedomostyData.find(
              (val) => val.vedId === item
            ).driverPoluchatel;
          resultVedomost.push({
            id: item,
            vedDataPlat: vedDataPlat.slice(0, 10),
            vedNumber: vedNumber,
            poluchatel: poluchatel,
            sumOrder: sumOrder,
            orders: orders,
            vedText: `Платежная ведомость №${vedNumber} (за заказы: ${orders.join(
              ","
            )})`
          });
        });
      }
      resultVedomost.sort((a, b) => {
        return a.id - b.id;
      });
      return this.claimArray.length
        ? [...resultVedomost, ...this.claimArray]
        : resultVedomost;
    },

    // Уникальный массив по месяцам, сравнение сумм заказов, выплат и процентов за этот год
    mounthArrayUniq() {
      const resultMonth = [],
        newMonth = [...new Set(this.yearToMonthData.map((obj) => obj.month))];
      if (newMonth) {
        newMonth.map((item) => {
          const sum = this.yearToMonthData
              .map((val) => {
                if (val.month === item) {
                  let nopaydOrder = 0;
                  if (!val.paymentSum && val.sumDriver !== 0) {
                    nopaydOrder = 1;
                  }
                  return {
                    paymentSum: val.paymentSum,
                    sumDriver: val.sumDriver,
                    sum5proc: val.sum5proc,
                    nopaydOrder
                  };
                } else {
                  return "";
                }
              })
              .filter((n) => n),
            sumPayment = Object.keys(sum).reduce(
              (a, b) => a + sum[b].paymentSum,
              0
            ),
            sumDriver = Object.keys(sum).reduce(
              (a, b) => a + sum[b].sumDriver,
              0
            ),
            sum5proc = Object.keys(sum).reduce(
              (a, b) => a + sum[b].sum5proc,
              0
            ),
            nopaydOrders = Object.keys(sum).reduce(
              (a, b) => a + sum[b].nopaydOrder,
              0
            ),
            sumDiff = sumDriver - sumPayment - sum5proc;
          resultMonth.push({
            id: item,
            sumPayment: +sumPayment.toFixed(2),
            sumDriver: +sumDriver.toFixed(2),
            sum5proc: +sum5proc.toFixed(2),
            sumDiff: +sumDiff.toFixed(2),
            ordersCount: sum.length,
            nopaydOrders
          });
        });
      }
      return resultMonth;
    },

    // Уникальный массив по месяцам, сравнение сумм заказов, выплат и процентов за всё время
    mounthArrayUniqToAllYears() {
      const resultMonth = [],
        newMonth = [
          ...new Set(
            this.yearToMonthDataAllYears.map((obj) => {
              let mount = obj.month;
              if (mount < 10) {
                mount = `0${mount}`;
              }
              return obj.year + "." + mount;
            })
          )
        ];
      newMonth.sort((a, b) => {
        return a - b;
      });
      if (newMonth) {
        newMonth.map((item) => {
          const sum = this.yearToMonthDataAllYears
              .map((val) => {
                const formated = item.split(".");
                if (val.month === +formated[1] && val.year === formated[0]) {
                  let nopaydOrder = 0;
                  if (!val.paymentSum && val.sumDriver !== 0) {
                    nopaydOrder = 1;
                  }
                  return {
                    paymentSum: val.paymentSum,
                    sumDriver: val.sumDriver,
                    sum5proc: val.sum5proc,
                    nopaydOrder
                  };
                } else {
                  return "";
                }
              })
              .filter((n) => n),
            sumPayment = Object.keys(sum).reduce(
              (a, b) => a + sum[b].paymentSum,
              0
            ),
            sumDriver = Object.keys(sum).reduce(
              (a, b) => a + sum[b].sumDriver,
              0
            ),
            sum5proc = Object.keys(sum).reduce(
              (a, b) => a + sum[b].sum5proc,
              0
            ),
            nopaydOrders = Object.keys(sum).reduce(
              (a, b) => a + sum[b].nopaydOrder,
              0
            ),
            sumDiff = sumDriver - sumPayment - sum5proc;
          resultMonth.push({
            id: +item.split(".")[1],
            year: +item.split(".")[0],
            sumPayment: +sumPayment.toFixed(2),
            sumDriver: +sumDriver.toFixed(2),
            sum5proc: +sum5proc.toFixed(2),
            sumDiff: +sumDiff.toFixed(2),
            ordersCount: sum.length,
            nopaydOrders
          });
        });
      }
      return resultMonth;
    },

    // Получение месяца и года последнего заказа
    getLastMonthYear() {
      if (this.mounthArrayUniqToAllYears.length > 0) {
        return this.mounthArrayUniqToAllYears[
          this.mounthArrayUniqToAllYears.length - 1
        ];
      } else {
        return null;
      }
    },

    // Получаем информацию по месяцам сравниваю массив с приходящими данными
    getFormatedMonth() {
      this.arrayMounth.map((item) => {
        if (this.mounthArrayUniq.length > 0) {
          const month = this.mounthArrayUniq.filter(
            (val) => val.id + 1 == item.id
          );
          if (month.length > 0) {
            const monthItem = month[0];
            if (monthItem.ordersCount > 0) {
              item.disabled = false;
            }
            if (
              (monthItem.sumDiff >= 0 && monthItem.sumDiff < 1) ||
              (monthItem.sumDiff < 0 && monthItem.sumDiff > -1)
            ) {
              item.pill = "green";
            } else if (monthItem.sumDiff < 0 && monthItem.sumDiff < -1) {
              item.pill = "orange";
            } else {
              if (monthItem.sumPayment > 0) {
                item.pill = "half";
              } else {
                item.pill = "red";
              }
            }
          }
        }
      });
      return this.arrayMounth;
    },

    // Баланс по неоплаченным заказам с учётом недоплат и переплат
    getBalanceAll() {
      return Object.keys(this.mounthArrayUniqToAllYears)
        .reduce((a, b) => a + this.mounthArrayUniqToAllYears[b].sumDiff, 0)
        .toFixed(2);
    },

    // Количество неоплаченных заказов
    nopaydOrdersSum() {
      return Object.keys(this.mounthArrayUniqToAllYears).reduce(
        (a, b) => a + this.mounthArrayUniqToAllYears[b].nopaydOrders,
        0
      );
    },
    // Массив претензий
    claimArray() {
      const temp = [];
      this.claimData.map((item, i) => {
        const sumOrder = item.driverPaid,
          sum5Proc = item.driver5Percent,
          vedDataPlat = new Date(item.claimDate).toLocaleDateString(),
          orders = item.orders
            .map((val) => val.orderId)
            .sort((a, b) => (a < b ? -1 : 1)),
          obj = {
            id: Date.now() + i,
            orders,
            poluchatel: "",
            sumOrder,
            sum5Proc,
            vedDataPlat,
            vedNumber: item.claimId1c,
            vedText: `Претензия клиента ${item.clientName} №${
              item.claimId1c
            } от ${vedDataPlat} (за заказы: ${orders.join(",")}`
          };
        temp.push(obj);
      });
      return temp;
    }
  },
  methods: {
    ...mapActions([
      "GET_TOKEN",
      "GET_TOKEN_REFRESH",
      "GET_USER_DATA",
      "REMOVE_TOKENS"
    ]),

    getThisMonth() {
      return new Date().getMonth() + 1;
    },

    getThisYear() {
      return new Date().getFullYear();
    },

    changeSelectedMounth(id, disabled) {
      if (!disabled) {
        this.arrayMounth.map((item, i) => {
          item.selected = false;
          if (i + 1 === id) {
            item.selected = true;
            item.disabled = false;
            this.selectedMounth = id;
            this.selectedMounthName = item.name;
          }
        });
      }
    },

    changeYear(value, disabled) {
      if (!disabled) {
        const newYear = this.selectedYear + value;
        if (newYear <= this.getThisYear() && newYear >= this.firstYear) {
          this.selectedMounth = null;
          this.selectedYear = newYear;
          // this.disabledYearRight = false;
          if (newYear === this.getThisYear()) {
            // this.disabledYearRight = true;
            this.changeSelectedMounth(this.getThisMonth());
          }
          if (newYear === this.firstYear) {
            this.disabledYearLeft = true;
            // this.disabledYearRight = false;
            this.changeSelectedMounth(12);
          }
          if (newYear > this.firstYear && newYear < this.getThisYear()) {
            this.disabledYearLeft = false;
            this.changeSelectedMounth(12);
          }
          this.arrayMounth.map((item) => {
            item.selected = false;
            item.disabled = true;
            item.pill = null;
            if (
              this.selectedMounth === item.id &&
              this.selectedYear === newYear
            ) {
              item.selected = true;
              item.disabled = false;
            }
          });
        }
      }
    },

    // Сменить отображение таблицы
    changeShortTable() {
      this.shortTable = !this.shortTable;
    },

    // Загрузка EXCEL JSON
    async downloadExcel() {
      await this.axios
        .post(
          `/api/autopark/report/download/json/xls?&title=${this.selectedMounthName} ${this.selectedYear}`,
          {
            vedomost: this.vedomostyUniq,
            lastVedomost: {},
            orders: this.orderStatusList,
            sums: {
              ordersSum: this.getOrdersSum,
              paydDriverSum: this.getDriverSum,
              paydToMonth: this.getAllMounthPayedSum,
              paydRemains: this.getMounthPayedSum
            }
          },
          {
            responseType: "blob",
            headers: { Authorization: `Bearer ${this.token}` }
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${this.fileName}-${this.getDateNow}.xls`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((e) => {
          this.errorTokenCode(e, "downloadExcel", this.downloadExcel);
        });
    },

    async refreshTokenHandler() {
      await this.axios
        .post("/api/token/refresh", {
          refresh_token: this.refreshToken
        })
        .then((response) => {
          this.GET_TOKEN(response.data.token);
          this.GET_TOKEN_REFRESH(response.data.refresh_token);
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (
            code === 401 &&
            message === "An authentication exception occurred."
          ) {
            this.REMOVE_TOKENS();
            this.$router.push("/auth");
          } else {
            console.error("Ошибка в запросе refreshToken" + e.message);
          }
        });
    },

    errorTokenCode(e, zaprosName, func) {
      const { code, message } = e.response.data;
      if (code === 401) {
        if (message === "Expired JWT Token") {
          this.refreshTokenHandler().then(() => {
            this.GET_USER_DATA();
            func();
          });
        } else if (message === "An authentication exception occurred.") {
          this.REMOVE_TOKENS();
          this.$router.push("/auth");
        }
      } else {
        console.error(`Ошибка в запросе ${zaprosName} ${e.message}`);
      }
    },

    // Получение данных по заказам
    async getDriverData() {
      this.loader = true;
      await this.axios
        .post(
          "/api/autopark/report/get",
          {
            startDate: this.startDate,
            endDate: this.endDate
          },
          { headers: { Authorization: `Bearer ${this.token}` } }
        )
        .then((response) => {
          if (response.data.error !== "Автопарк не определен! [AA:3]") {
            this.ordersMonth = response.data.response;
          } else {
            this.REMOVE_TOKENS();
            this.$router.push("/auth");
          }
        })
        .catch((e) => {
          console.log(e.message);
          this.errorTokenCode(e, "getDriverData", this.getDriverData);
        });
    },

    // Получение данных по ведомостям
    async getVedomostyData() {
      await this.axios
        .post(
          "/api/autopark/report/vedomost/get",
          {
            startDate: this.startDate,
            endDate: this.endDate
          },
          { headers: { Authorization: `Bearer ${this.token}` } }
        )
        .then((response) => {
          if (response.data.error !== "Автопарк не определен! [AA:3]") {
            this.vedomostyData = response.data.response;
          } else {
            this.REMOVE_TOKENS();
            this.$router.push("/auth");
          }
        })
        .catch((e) => {
          this.errorTokenCode(e, "getVedomostyData", this.getVedomostyData);
        });
    },
    // Получение данных по претензиям по периоду
    async getClaimData() {
      await this.axios
        .post(
          "/api/autopark/report/claim/get",
          {
            startDate: this.startDate,
            endDate: this.endDate
          },
          { headers: { Authorization: `Bearer ${this.token}` } }
        )
        .then((response) => {
          // console.log(response.data.response);
          if (response.data.error !== "Автопарк не определен! [AA:3]") {
            this.claimData = response.data.response;
          } else {
            this.REMOVE_TOKENS();
            this.$router.push("/auth");
          }
        })
        .catch((e) => {
          this.errorTokenCode(e, "getClaimData", this.getClaimData);
        });
    },

    // Получение данных по месяцам за этот год
    async getYearDataToEveryMonth() {
      await this.axios
        .post(
          "/api/autopark/report/all",
          {
            startDate: `${this.selectedYear}-01-01`,
            endDate: `${this.selectedYear}-12-31`
          },
          { headers: { Authorization: `Bearer ${this.token}` } }
        )
        .then((response) => {
          if (response.data.error !== "Автопарк не определен! [AA:3]") {
            this.yearToMonthData = response.data.response;
          } else {
            this.REMOVE_TOKENS();
            this.$router.push("/auth");
          }
          this.loader = false;
        })
        .catch((e) => {
          this.errorTokenCode(
            e,
            "getYearDataToEveryMonth",
            this.getYearDataToEveryMonth
          );
        });
    },

    // Получение данных по месяцам за всё время
    async getYearDataToEveryMonthToAllYears() {
      await this.axios
        .post(
          "/api/autopark/report/all",
          {
            startDate: `${this.firstYear}-01-01`,
            endDate: `${this.getThisYear()}-12-31`
          },
          { headers: { Authorization: `Bearer ${this.token}` } }
        )
        .then((response) => {
          if (response.data.error !== "Водитель не определен! [DA:3]") {
            this.yearToMonthDataAllYears = response.data.response;
            if (this.yearToMonthDataAllYears.length > 0) {
              this.emptyData = false;
            } else {
              this.loader = false;
              this.emptyData = true;
            }
          } else {
            this.REMOVE_TOKENS();
            this.$router.push("/auth");
          }
        })
        .catch((e) => {
          this.errorTokenCode(
            e,
            "getYearDataToEveryMonthToAllYears",
            this.getYearDataToEveryMonthToAllYears
          );
        });
    },

    // Подсветка вложенных заказов из ведомости
    showIncludedOrders(orders, id, payment) {
      this.paymentsElemets = payment;
      document.addEventListener("click", this.dropHandler);
      this.selectedOrders = [];
      this.selectedVedomost = id;
      orders.forEach((item) => {
        this.selectedOrders.push(item);
      });
    },

    // Клик не на элементе dropdown
    dropHandler(e) {
      if (!this.paymentsElemets.includes(e.target)) {
        this.selectedOrders = [];
        this.selectedVedomost = 0;
      }
    }
  },
  watch: {
    selectedMounth(id) {
      this.arrayMounth.map((item, i) => {
        item.selected = false;
        if (i + 1 === id) {
          item.selected = true;
          this.selectedMounth = id;
          this.selectedMounthName = item.name;
        }
      });
      this.getDriverData().then(() => {
        this.getVedomostyData();
        this.getClaimData();
        this.getYearDataToEveryMonth();
      });
    },
    selectedYear(id) {
      this.selectedYear = id;
      this.getDriverData().then(() => {
        this.getVedomostyData();
        this.getClaimData();
        this.getYearDataToEveryMonth();
      });
    }
  }
};
</script>

<style lang="scss">
.main__pages--center {
  margin-left: $main-margin;
  text-align: center;

  .main__pages--title {
    color: $color-basic;
    font-size: 16px;
    margin: 30px auto;
  }
}

#changeYear {
  @include flex;
  @include flex-content-start;
  font-weight: bold;
  font-size: 18px;
  margin-right: 20px;

  img {
    cursor: pointer;
    padding-bottom: 2px;

    &.chevron-left {
      margin-right: 14px;
    }

    &.chevron-right {
      margin-left: 14px;
    }

    &.chevron-disabled {
      opacity: 0.5;
    }
  }
}

.main__pages--block,
.main__pages--filters {
  @include flex;
  @include flex-content-start;
  // max-width: 1440px !important;

  .main__pages--mounth {
    @include flex;
    background: $color-white;
    border: 1px solid $color-grey-light;
    border-radius: 5px;
    color: $color-black;
    font-size: 13px;
    height: 32px;
    margin-right: 6px;
    padding: 0 17px;
    position: relative;

    &.active {
      background: $color-yellow-dark;

      &:hover {
        background: $color-yellow-dark !important;
      }
    }

    &.disabled {
      background: $color-grey-slight;
      cursor: not-allowed;

      &:hover {
        background: $color-grey-slight;
        cursor: not-allowed;
      }
    }

    &:hover,
    &.selected {
      background: $color-beige;
      cursor: pointer;
    }

    .pill__green,
    .pill__red,
    .pill__half,
    .pill__orange {
      border-radius: 35.5263px 35px 35px 35.5263px;
      height: 13px;
      position: absolute;
      right: -8px;
      top: -6px;
      width: 26px;
      z-index: 3;
    }

    .pill__green {
      background: $color-green;
    }

    .pill__red {
      background: $color-red;
    }

    .pill__orange {
      background: $color-orange;
    }

    .pill__half {
      background: linear-gradient(to right, $color-green 50%, $color-red 50%)
        no-repeat;
    }
  }

  .button__clear {
    @include flex;
    background: #f7f7f7;
    border: 1px solid $color-grey-light;
    border-radius: 8px;
    cursor: pointer;
    height: 32px;

    width: 34px;

    &:hover {
      opacity: 0.7;
    }
  }

  .main__select {
    margin-right: 6px;
    width: 129px !important;

    select {
      background: $color-white;
      border-radius: 5px;
      color: $color-black;
      padding-left: 45px;
      width: 129px !important;

      &:after {
        right: -40px !important;
      }

      &:disabled {
        background: $color-grey-light;
        cursor: not-allowed;
      }
    }
  }
}

tr.active {
  background: $color-beige !important;
}

td.sum {
  font-weight: bold;
  padding-right: 13px;
  text-align: right !important;
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase !important;
}

.nowrap {
  @include flex;
  @include flex-content-start;
  overflow: hidden;
  padding: 0 !important;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.main__loader {
  @include flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

#mounthSelect {
  display: none;
}

.sum__parent {
  width: 112px;
}

.sum__block {
  font-weight: bold;
  // text-align: right;
  width: 100px;
}

.sum__block--normal {
  // text-align: right;
  width: 100px;
}

.block__balance {
  @include flex;
  @include flex-content-start;
  width: 300px;
  height: 60px;
  margin-bottom: 20px;
  div {
    font-size: 12px;
    margin-bottom: 5px;
  }

  h3 {
    font-weight: bold;
    font-size: 28px;
  }
}

@media (max-width: 1199.98px) {
  .main__pages--block {
    display: block !important;
  }

  #changeYear {
    font-size: 27px;
    line-height: 31px;
    margin-bottom: 25px;
  }

  .main__pages--filters {
    display: grid;
    grid-template-rows: 2.3rem 2.3rem 2.3rem; /* 3 строки */
    grid-template-columns: 16% 16% 16% 16% 16% 16%; /* 6 столбцов */
  }
}

@media (max-width: 767.98px) {
  .main__pages--filters {
    grid-template-rows: 2.3rem 2.3rem 2.3rem 2.3rem; /* 4 строки */
    grid-template-columns: 25% 25% 25% 25%; /* 4 столбца */
  }
}

@media (max-width: 575.98px) {
  #changeYear {
    @include flex-content-center;
  }

  .block__balance {
    @include flex-content-center;
    text-align: center;

    div {
      font-size: 20px;
      line-height: 23px;
    }
    width: 100%;
  }

  .main__pages--filters {
    grid-template-rows: 2.3rem 2.3rem 2.3rem 2.3rem; /* 4 строки */
    grid-template-columns: 33% 33% 33%; /* 3 столбца */
  }
}
</style>
