<template>
  <div class="payments__table">
    <div>
      <h2>ВЫПЛАТЫ {{ month }} {{ year }}</h2>
      <table>
        <thead>
          <tr>
            <th style="width: 40px">№</th>
            <th style="width: 100px">Дата платежа</th>
            <th style="width: 521px">Описание</th>
            <th class="sum__parent">Сумма</th>
            <th style="width: 260px">ФИО получателя</th>
            <th style="width: 260px">Комментарий к ведомости</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(vedomost, idx) in vedomostyUniq"
            :key="vedomost.id"
            :class="[
              selectedVedomost && selectedVedomost === vedomost.id
                ? `active`
                : ``,
              idx % 2 !== 1 ? 'striple' : ''
            ]"
            @click="showIncludedOrders(vedomost.orders, vedomost.id)"
          >
            <td style="width: 40px" ref="payments">
              {{ idx + 1 }}
            </td>
            <td style="width: 100px" ref="payments">
              {{ vedomost.vedDataPlat }}
            </td>
            <td :title="vedomost.vedText" ref="payments">
              <div style="width: 509px" class="nowrap" ref="payments">
                {{ vedomost.vedText }}
              </div>
            </td>
            <td class="sum__parent" ref="payments">
              <div class="sum__block--normal" ref="payments">
                {{ vedomost.sumOrder | rounding }}
              </div>
            </td>
            <td style="width: 260px" ref="payments">
              {{ vedomost.poluchatel }}
            </td>
            <td style="width: 260px" ref="payments">&nbsp;</td>
          </tr>
          <tr>
            <td colspan="3" class="sum">
              ИТОГО ВЫПЛАЧЕНО ЗА {{ month }}
              {{ year }}
            </td>
            <td class="sum__parent">
              <div class="sum__block">
                {{ getAllMounthPayedSum | rounding }}
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="sum">
              <span v-if="getMounthPayedSum >= 0">ОСТАЛОСЬ ВЫПЛАТИТЬ</span
              ><span v-else>ПЕРЕПЛАТА</span> ЗА
              {{ month }}
              {{ year }}
            </td>
            <td class="sum__parent">
              <div
                class="sum__block"
                :class="
                  getMounthPayedSum >= 0 ? `text__success` : `text__danger`
                "
              >
                {{ getMounthPayedSum | rounding }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportPaymentTable",
  props: {
    month: String,
    year: Number,
    vedomostyUniq: Array,
    selectedVedomost: Number,
    getVedomostySumPercent: Number,
    endDateVedomost: String,
    getAllMounthPayedSum: String,
    getMounthPayedSum: String
  },
  filters: {
    rounding(value, notTail = 0) {
      if (!value || value === "" || value == "0") {
        return 0;
      } else {
        let tail = "";
        value = value.toString();
        if (value.includes(".")) {
          const arr = value.split(".");
          value = arr[0];
          if (arr[1].length === 1) {
            tail = "." + arr[1] + "0";
          } else if (arr[1].length === 2) {
            tail = "." + arr[1];
          } else {
            tail =
              "." +
              (Math.round(+arr[1].slice(0, 3) / 5) * 5).toString().slice(0, 2);
          }
        } else {
          tail = ".00";
        }
        value = value.replace(/\s/g, "");
        if (notTail) {
          tail = "";
        }
        if (value.length > 3) {
          let right = value.slice(-3),
            left = value.slice(0, -3),
            leftOnce = "";
          if (left.length > 3) {
            leftOnce = left.slice(0, -3);
            left = left.slice(-3);
          }
          if (leftOnce) {
            return leftOnce + " " + left + " " + right + tail;
          } else {
            return left + " " + right + tail;
          }
        } else {
          return value + tail;
        }
      }
    }
  },
  methods: {
    showIncludedOrders(orders, id) {
      this.$emit("showIncludedOrders", orders, id, this.$refs.payments);
    }
  }
};
</script>

<style lang="scss">
.payments__table {
  @include flex;
  @include flex-align-start;
  @include flex-content-start;
  max-width: 1291px !important;
  overflow-x: auto !important;

  table {
    margin-top: 25px;
    width: 1291px;

    th {
      height: 32px !important;
    }

    td {
      text-align: center;

      div {
        margin: 0 5px;
      }
    }
  }
}
</style>
